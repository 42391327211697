import React, { useEffect, useState, useRef } from "react";
import actionCable from "actioncable";
import ChatInput from "./ChatInput";
import Axios from "axios";
import consumer from "../../channels/consumer";

// const CableApp = {};
// CableApp.cable = actionCable.createConsumer("ws://qa.admin.najik.com/cable");

function Chat(props) {
  const [userList, setUserList] = useState();
  const [messageHistory, setMessageHistory] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const endMessageRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [recipientId, setRecipientId] = useState();
  const [userName, setUserName] = useState();
  const [subscription, setSubscription] = useState(null);

  const current_user = props?.current_user;
  console.log("current_user", current_user);

  useEffect(() => {
    endMessageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [messages]);

  const actionCable = (item) => {
    const recipient_id = item.chat.user_names[0].id;
    if (subscription) {
      subscription.unsubscribe();
    }

    var subscriptionInstance = consumer.subscriptions.create(
      {
        channel: "ChatChannel",
        sender_id: { current_user },
        recipient_id: { recipient_id },
        uuid: "08c7b14e-ff58-4be3-9e6e-131c218e6f03",
      },
      {
        received: (reply) => {
          setMessages((messages) => [...messages, reply]);
        },
      }
    );

    setSubscription(subscriptionInstance);
  };

  useEffect(() => {
    Axios.get(`/api/chat_history?user=${current_user}`).then((res) => {
      setUserList(res.data);
    });
  }, []);

  useEffect(() => {
    if (messageHistory !== undefined || "") {
      const prevMessages = messageHistory?.map((item) => {
        return item?.message;
      });
      setMessages(prevMessages);
    }
  }, [messageHistory]);

  useEffect(() => {
    if (selectedUser !== undefined || "") {
      Axios.get(
        `api/message_history?chat_id=${selectedUser}&user=${current_user}`
      ).then((res) => {
        setMessageHistory(res.data);
        console.log("message", res.data);
      });
    }
  }, [selectedUser]);

  const viewChat = (item) => {
    setSelectedUser(item.chat.id);
    setRecipientId(item.chat.user_names[0].id);
    setUserName(item.chat.user_names[0].name);
    actionCable(item);
  };

  return (
    <div className="chat-display chat-section">
      <div className="chat-list-block">
        <div className="chat-list-header">
          <p>Chats</p>
        </div>

        {userList?.map((item, index) => {
          return (
            <div
              className={`chat-user ${
                selectedUser === item.chat.id && "active"
              }`}
              onClick={() => {
                viewChat(item);
              }}
              key={index}
            >
              <img src={require("../../images/users/1.jpg")} alt="user-img" />
              <p> {item?.chat?.user_names[0]?.name}</p>
            </div>
          );
        })}
      </div>

      <div className=" message-list-block">
        <div className="message-list-header">
          <img src={require("../../images/users/1.jpg")} alt="user-img" />

          <p>{userName}</p>
        </div>
        <div className="message-list">
          {/* <p>{messages?.length}</p> */}
          {messages?.map((message, index) => {
            return message?.user_id === current_user ? (
              <div className="sender_message-card" key={index}>
                <p>{message.content} </p>

                <img
                  className="chat-photo"
                  src={require("../../images/users/3.jpg")}
                  alt="chat-img"
                />
              </div>
            ) : (
              <div className="recipient-message-card" key={index}>
                <img
                  className="chat-photo"
                  src={require("../../images/users/3.jpg")}
                  alt="chat-img"
                />
                <p>{message.content}</p>
              </div>
            );
          })}
          <div className="mb-100" />
          <div ref={endMessageRef} />
        </div>
        <ChatInput
          user={current_user}
          recipientId={recipientId}
          messages={messages}
          setMessages={setMessages}
          cable={consumer}
        />
      </div>
    </div>
  );
}

export default Chat;
