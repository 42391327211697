import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

class ProductItem extends Component {
  componentDidMount() {
    let that = this;
    $(`[data-behavior='multiDropzone${this.props.productIndex}']`).dropzone({
      acceptedFiles: ".jpeg,.jpg,.png,.gif,.svg",
      url: "a",
      autoProcessQueue: false,
      init: function () {
        this.on("thumbnail", function (file, data) {
          that.props.imageAdd(data);
        });
      },
    });
  }
  render() {
    const {
      tags,
      productIndex,
      categoryInfos,
      onCategoryInfoChange,
      suppliers,
    } = this.props;

    return (
      <div>
        <hr />
        <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
          <li className="nav-item">
            <a
              href={"#general-info" + productIndex}
              data-toggle="tab"
              aria-expanded="false"
              className="nav-link active"
            >
              <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
              <span className="d-none d-lg-block">Product Information</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              href={"#category-info" + productIndex}
              data-toggle="tab"
              aria-expanded="true"
              className="nav-link"
            >
              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
              <span className="d-none d-lg-block">Category Information</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              href={"#supplier-price" + productIndex}
              data-toggle="tab"
              aria-expanded="true"
              className="nav-link"
            >
              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
              <span className="d-none d-lg-block">Supplier Price</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              href={"#product-attributes" + productIndex}
              data-toggle="tab"
              aria-expanded="true"
              className="nav-link"
            >
              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"></i>
              <span className="d-none d-lg-block">Product Attributes</span>
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane show active"
            id={"general-info" + productIndex}
          >
            <div className="form-inputs row">
              <div className="col-md-4">
                <div className="form-group string optional product_name">
                  <label className="string optional" for="product_name">
                    Product Name
                  </label>
                  <input
                    className="form-control string optional"
                    type="text"
                    name="name"
                    onChange={(e) => this.props.onInputChange(e.target)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group decimal optional product_price">
                  <label className="decimal optional" for="product_price">
                    Market Price
                  </label>
                  <input
                    className="form-control numeric decimal optional"
                    type="number"
                    step="any"
                    name="price"
                    onChange={(e) => this.props.onInputChange(e.target)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group decimal optional product_discount">
                  <label className="decimal optional" for="product_discount">
                    Discount
                  </label>
                  <input
                    className="form-control numeric decimal optional"
                    type="number"
                    step="any"
                    name="discount"
                    onChange={(e) => this.props.onInputChange(e.target)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label>Product Image</label>
                <div
                  className="form-control dropzone"
                  data-behavior={"multiDropzone" + this.props.productIndex}
                ></div>
              </div>
              <div className="col-md-6">
                <div className="form-group text optional product_description ">
                  <label className="text optional" for="product_description">
                    Description
                  </label>
                  <textarea
                    style={{ height: "150px" }}
                    className="form-control text optional ckeditor"
                    rows="6"
                    name="description"
                    onChange={(e) => this.props.onInputChange(e.target)}
                  ></textarea>
                </div>
              </div>
              <div className="clearfix"></div>
              <div id="Tags" className="form-group col-md-12">
                <label>Tags</label>
                <CreatableSelect
                  placeholder="Select"
                  id="tags"
                  isMulti
                  options={tags.map((tag) => ({
                    label: tag.tagname,
                    value: tag.id,
                  }))}
                  onChange={this.props.onTagsChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Dimensions</label>
                <div className="row no-margins">
                  <input
                    type="number"
                    id="length"
                    onChange={(e) =>{this.props.onInputChange(e.target)}}
                    className="col-md-3 form-control"
                    name="length"
                    placeholder="Length"
                  />{" "}
                  <span class="m-2"> X </span>{" "}
                  <input
                    type="number"
                    id="breadth"
                    name="breadth"
                    placeholder="Breadth"
                    onChange={(e) => this.props.onInputChange(e.target)}
                    className="col-md-3 form-control"
                  />{" "}
                  <span class="m-2"> X </span>{" "}
                  <input
                    type="number"
                    id="height"
                    onChange={(e) => this.props.onInputChange(e.target)}
                    className="col-md-3 form-control"
                    name="height"
                    placeholder="Height"
                  />
                  <span class="m-2"> (cm) </span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Weight</label>
                <input
                  className="form-control weight-input"
                  type="number"
                  onChange={(e) => this.props.onInputChange(e.target)}
                  id="weight"
                  name="weight"
                />
                <span className="kg-unit">kg</span>
              </div>
            </div>
          </div>
          <div className="tab-pane" id={"category-info" + productIndex}>
            <div
              className="row form-group"
              id="category_additional_fields"
              style={{ width: "100%", marginLeft: "0" }}
            >
              {categoryInfos.map((fieldInfo) => {
                if (!fieldInfo.is_shown) {
                  return null;
                } else if (fieldInfo.label_type === "decimal") {
                  return (
                    <div className="col-md-4">
                      <label>{fieldInfo.label}</label>
                      <input
                        className="form-control"
                        name={fieldInfo.label}
                        type="number"
                        placeholder={fieldInfo.append_value}
                        onChange={onCategoryInfoChange}
                      />
                    </div>
                  );
                } else if (fieldInfo.label_type === "text") {
                  return (
                    <div className="col-md-4">
                      <label>{fieldInfo.label}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={fieldInfo.label}
                        placeholder={fieldInfo.append_value}
                        onChange={onCategoryInfoChange}
                      />
                    </div>
                  );
                } else if (fieldInfo.label_type === "date") {
                  return (
                    <div className="col-md-4">
                      <label>{fieldInfo.label}</label>
                      <input
                        className="form-control"
                        type="date"
                        name={fieldInfo.label}
                        placeholder={fieldInfo.append_value}
                        onChange={onCategoryInfoChange}
                      />
                    </div>
                  );
                } else if (fieldInfo.label_type === "option") {
                  return (
                    <div className="col-md-4">
                      <label>{fieldInfo.label}</label>
                      <select
                        className="custom-select"
                        onChange={onCategoryInfoChange}
                        name={fieldInfo.label}
                      >
                        {fieldInfo.options.map((option) => {
                          return (
                            <option value={option.option}>
                              {option.option} {fieldInfo.append_value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div class="tab-pane" id={"supplier-price" + productIndex}>
            <div
              id="supplierPriceForm"
              class="row form-group"
              style={{ width: "100%", marginLeft: "0" }}
            >
              {this.props.product.supplierPrices.map(
                (supplier, supplierIndex) => {
                  return (
                    <>
                      <br />
                      <div class="form-inputs row w100">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Supplier</label>
                            <Select
                              placeholder="Select"
                              id="supplier"
                              options={this.props.suppliers.map(
                                (suppliers) => ({
                                  label: suppliers.company_name,
                                  value: suppliers.id,
                                })
                              )}
                              onChange={(option) =>
                                this.props.handleSupplierChange(
                                  option.value,
                                  supplierIndex
                                )
                              }
                              value={this.props.suppliers
                                .map((supplier) => ({
                                  label: supplier.id,
                                  value: supplier.company_name,
                                }))
                                .find((option) => {
                                  return option.value === supplier.supplier_id;
                                })}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Price</label>
                            <input
                              class="form-control"
                              type="number"
                              value={supplier.price}
                              onChange={(e) =>
                                this.props.onSupplierPriceChange(
                                  e.target.value,
                                  supplierIndex
                                )
                              }
                            />
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <br />
                    </>
                  );
                }
              )}
            </div>

            <div class="col-md-12">
              <div class="float-right ">
                <button
                  id="addSupplierPriceForm"
                  class="btn btn-warning mb-2 mr-3"
                  onClick={this.props.addSuppliers}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div class="tab-pane" id={"product-attributes" + productIndex}>
            <div
              id="productAttributesForm"
              class="row form-group"
              style={{ width: "100%", marginLeft: "0" }}
            >
              {this.props.product.productAttributes.map(
                (attribute, attributeIndex) => {
                  return (
                    <>
                      <br />
                      <div
                        id="attributeForm"
                        class="form-inputs row w100"
                        data-form-type="new"
                      >
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Attribute</label>
                            <input
                              class="form-control"
                              onChange={(e) =>
                                this.props.onProductAttributeChange(
                                  e.target,
                                  attributeIndex
                                )
                              }
                              name="attribute"
                              value={attribute.attribute_name}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Value</label>
                            <input
                              class="form-control"
                              name="value"
                              value={attribute.value}
                              onChange={(e) =>
                                this.props.onProductAttributeChange(
                                  e.target,
                                  attributeIndex
                                )
                              }
                            />
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <br />
                    </>
                  );
                }
              )}
            </div>
            <div class="col-md-12">
              <div class="float-right ">
                <button
                  id="addProductAttributes"
                  class="btn btn-warning mb-2 mr-3"
                  onClick={this.props.addProductAttributes}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductItem;
