// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("parsleyjs");

// import "css/style";
import $ from "jquery";
import "select2";
global.$ = jQuery;

global.toastr = require("toastr");

document.addEventListener("turbolinks:load", () => {
  $(".select2").select2({ width: "100%" });
  $(".select2WithTags").select2({ tags: true, width: "100%" });
});

// DROPZONE
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
Dropzone.autoDiscover = false;
window.Dropzone = Dropzone;
document.addEventListener("turbolinks:load", () => {
  $("[data-behavior='singleDropzone']").dropzone({
    url: "a",
    autoProcessQueue: false,
    maxFiles: 1,
    uploadMultiple: false,
    acceptedFiles: ".jpeg,.jpg,.png,.gif,.svg",
    addRemoveLinks: true,
    init: function () {
      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  });
  $("[data-behavior='multiDropzone']").dropzone({
    acceptedFiles: ".jpeg,.jpg,.png,.gif,.svg",
    url: "a",
    autoProcessQueue: false,
    addRemoveLinks: true,
  });
});

document.addEventListener("turbolinks:load", () => {
  // disable mousewheel on a input number field when in focus
  // (to prevent Cromium browsers change the value when scrolling)
  $("form").on("focus", "input[type=number]", function (e) {
    $(this).on("wheel.disableScroll", function (e) {
      e.preventDefault();
    });
  });
  $("form").on("blur", "input[type=number]", function (e) {
    $(this).off("wheel.disableScroll");
  });

  $("#multipleSelect").multipleSelect({
    multiple: true,
    multipleWidth: 200,
    filter: true,
    filterGroup: true,
    tags: true,
    placeholder: "Select",
    width: "100%",
  });
});

import "bootstrap";
import flatpickr from "flatpickr";
require("datatables.net-dt");
require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.html5.min.js");
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.min.js";
import "datatables.net-plugins/sorting/custom-data-source/dom-text.js";
import "datatables.net-plugins/sorting/custom-data-source/dom-text-numeric.js";
import "datatables.net-plugins/sorting/custom-data-source/dom-select.js";
import "datatables.net-plugins/sorting/custom-data-source/dom-checkbox.js";
require("pdfmake/build/pdfmake");
import jsZip from "jszip";
window.JSZip = jsZip;

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
});
require("packs/app-style-switcher");
// require("packs/feather.min");
require("packs/perfect-scrollbar.jquery.min");
require("packs/sidebarmenu");

const feather = require("feather-icons");
document.addEventListener("turbolinks:load", function () {
  feather.replace();
  window.feather = feather;
});
function reInitFeatherIcons() {
  feather.replace();
}
window.reInitFeatherIcons = reInitFeatherIcons;

import "multiple-select/dist/multiple-select.css";
import "multiple-select";

//CKEDITOR
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import BlockQuotePlugin from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";

var ckeditorInstances = {};
function ckInit() {
  document.getElementsByClassName("ckeditor").forEach((editor) => {
    if (!editor.parentElement.getElementsByClassName("ck-editor").length) {
      ClassicEditor.create(editor, {
        plugins: [
          Bold,
          Italic,
          Essentials,
          Paragraph,
          HeadingPlugin,
          LinkPlugin,
          ListPlugin,
          BlockQuotePlugin,
          AutoformatPlugin,
          Underline,
          Strikethrough,
          Code,
          Subscript,
          Superscript,
        ],
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
      })
        .then((editor) => {
          ckeditorInstances[editor.ui.editor.sourceElement.id] = editor;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });
}

document.addEventListener("turbolinks:load", () => {
  ckInit();
  function objectifyForm(formArray) {
    //serialize data function
    var returnArray = {};
    for (var i = 0; i < formArray.length; i++) {
      returnArray[formArray[i]["name"]] = formArray[i]["value"];
    }
    return returnArray;
  }
  
  window.objectifyForm = objectifyForm;
});
window.ckeditorInstances = ckeditorInstances;
window.ckInit = ckInit;

function dataTableInitialization() {
  $(document).on("turbolinks:load", function () {
    var shouldInit;

    const initializeDatatable = (datatableClass) => {
      $(`.${datatableClass} tbody tr`).each(function (i) {
        shouldInit = $(this).parent().parent().html().indexOf("SN</th>") == -1;
        if (shouldInit) {
          $(this).prepend("<td></td>");
        }
      });
      $(`.${datatableClass} thead tr,.${datatableClass} tfoot tr`).each(
        function (i) {
          if (shouldInit) {
            $(this).prepend("<th width='40px'>SN</th>");
          }
        }
      );
    };

    window.initializeDatatable = initializeDatatable;

    initializeDatatable("dataTbl");
    initializeDatatable("dataTblNoScroll");

    //Created at find its index if available and sort using it else default sort
    var defaultOrderIndex = Array.from(
      $("table").find("tr")[0].children
    ).findIndex((header) => header.innerHTML.toLowerCase() === "created at");
    let columnDefs = [];

    //Dynamically adding sorting requires datatables.net-plugins
    $("table")
      .find("tr")[1]
      .cells.forEach((tditem, index) => {
        if ($(tditem.children).is("select")) {
          columnDefs.push({ targets: [index], orderDataType: "dom-select" });
        } else if ($(tditem.children).is("input:checkbox")) {
          columnDefs.push({
            targets: [index],
            orderDataType: "dom-checkbox",
          });
        } else if ($(tditem.children).is("input[type=number]")) {
          columnDefs.push({
            targets: [index],
            orderDataType: "dom-text-numeric",
          });
        } else if ($(tditem.children).is("input:text")) {
          columnDefs.push({ targets: [index], orderDataType: "dom-text" });
        } else if (
          $("table").find("tr")[0].cells[index].innerText === "Actions" ||
          $("table").find("tr")[0].cells[index].innerText === "SN" ||
          $(tditem.children).is("img")
        ) {
          columnDefs.push({
            targets: [index],
            orderable: false,
            searchable: false,
          });
        } else if (
          $("table").find("tr")[0].cells[index].innerText.toLowerCase() ===
          "created at"
        ) {
          columnDefs.push({
            targets: [index],
            visible: false,
          });
        }
      });
    const dataTableOptions = {
      bPaginate: true,
      bLengthChange: false,
      bFilter: true,
      iDisplayLength: 50,
      bInfo: false,
      ordering: true,
      bAutoWidth: false,
      dom: "Bfrtip",
      scrollX: true,
      sFilterInput: "form-control custom-radius",
      sLengthSelect: "form-control custom-radius",
      buttons: [
        {
          extend: "excel",
          text: '<i class="far fa-file-excel"></i> Excel',
          className:
            "btn waves-effect waves-light btn-rounded btn-outline-primary",
          init: function (api, node, config) {
            $(node).removeClass("dt-button buttons-excel buttons-html5");
          },
        },
        {
          extend: "csv",
          text: '<i class="fas fa-file-csv"></i> CSV',
          className:
            "btn waves-effect waves-light btn-rounded btn-outline-warning",
          init: function (api, node, config) {
            $(node).removeClass("dt-button buttons-csv buttons-html5");
          },
        },
        {
          extend: "pdf",
          text: '<i class="far fa-file-pdf"></i> PDF',
          className:
            "btn waves-effect waves-light btn-rounded btn-outline-success",
          init: function (api, node, config) {
            $(node).removeClass("dt-button buttons-pdf buttons-html5 ");
          },
        },
      ],
      columnDefs: columnDefs,
      order: [[1, "asc"]],
      language: {
        search: "_INPUT_", // Removes the 'Search' field label
        searchPlaceholder: "Search", // Placeholder for the search box
      },
      pagingType: "simple_numbers",
      fnRowCallback: function (nRow, aData, iDisplayIndex) {
        $("td:first", nRow).html(iDisplayIndex + 1);
        return nRow;
      },
      fnDrawCallback: function (oSettings) {
        feather.replace();
        initPaginateClass();
      },
    };

    // If created at column head found
    if (defaultOrderIndex !== -1) {
      dataTableOptions.order = [[defaultOrderIndex, "desc"]];
    }

    window.dataTableOptions = dataTableOptions;

    var dataTbl = $(".dataTbl").DataTable(dataTableOptions);
    dataTableOptions.scrollX = false;
    var dataTblNoScroll = $(".dataTblNoScroll").DataTable(dataTableOptions);
    document.addEventListener("turbolinks:before-cache", function () {
      if (dataTbl !== null) {
        dataTbl.destroy();
        dataTbl = null;
      }
      if (dataTblNoScroll !== null) {
        dataTblNoScroll.destroy();
        dataTblNoScroll = null;
      }
    });
  });
}
if ($("table").attr("class")?.includes("dataTbl")) {
  dataTableInitialization();
}

// import css
require("flatpickr/dist/flatpickr.css");
import "select2/dist/css/select2.css";
import "css/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context("../images", true);
$(document).on("turbolinks:before-cache", function () {
  if ($(".toast") !== null) $(".toast").remove();
  if ($(".select2") !== null) $(".select2").select2("destroy");

  // $('.previous ').addClass('btn waves-effect waves-light btn-rounded btn-outline-success').removeClass('paginate_button');
});
function initPaginateClass() {
  $(".previous")
    .addClass(
      "btn waves-effect waves-light btn-rounded btn-outline-success mr-1"
    )
    .removeClass("paginate_button");
  $(".next")
    .addClass(
      "btn waves-effect waves-light btn-rounded btn-outline-success ml-1"
    )
    .removeClass("paginate_button");
  $(".current")
    .addClass("btn waves-effect waves-light btn-circle btn-outline-success")
    .removeClass("paginate_button");
  $(".paginate_button").addClass(
    "btn waves-effect waves-light btn-circle btn-outline-success"
  );
}
$(document).on("turbolinks:load", function () {
  initPaginateClass();

  $(".preloadingForm").on("submit", function (e) {
    $(".preloader-active").show();
  });
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


