import React, { useState } from "react";

function ChatInput({ user, recipientId, messages, setMessages }) {
  const [chatInput, setChatInput] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      handleSubmit(event);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();
    if (chatInput !== "") {
      fetch(`/api/create_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender_id: user,
          recipient_id: recipientId,
          content: chatInput,
          uuid: "08c7b14e-ff58-4be3-9e6e-131c218e6f03",
          user_type: "sender",
        }),
      }).then((r) => {
        if (r.ok) {
          r.json().then((data) => {
            setChatInput("");

            setMessages([...messages, data]);
          });
        }
      });
    }
  }

  return (
    <div className="chat-input">
      <form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center message-send">
          <textarea
            name="chat_input"
            value={chatInput}
            rows="2"
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button type="submit" className="send-btn">
            <i class="far fa-paper-plane"></i>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatInput;
