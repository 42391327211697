import React, { Component } from "react";
import Select from "react-select";
import Dropzone from "react-dropzone";
import ProductItem from "./ProductItem";
import axios from "axios";
import produce from "immer";
import { hot } from "react-hot-loader/root";
class ProductsEntry extends Component {
  state = {
    products: [
      {
        name: "",
        price: "",
        description: "",
        images: [],
        discount: "",
        tags: [],
        length: "",
        breadth: "",
        height: "",
        weight: "",
        additional: {},
        supplierPrices: [],
        productAttributes: [],
      },
    ],
    categoryInfos: [],
    supplier: {},
    category: {},
  };

  additional = {};

  addCategoryInfos = async (categoryVal) => {
    let { data } = await axios.get("/category_information", {
      params: {
        category_id: categoryVal,
      },
    });
    data.forEach((info) => {
      if (info.is_shown) this.additional[info.label] = "";
    });
    this.setState(
      produce(this.state, (draft) => {
        draft.categoryInfos = data;
        draft.products.forEach((product) => {
          product.additional = this.additional;
        });
      })
    );
  };

  onSubmit = async () => {
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    let { data } = await axios.post("/admin/products_bulk_create", {
      products: this.state.products,
      category_id: this.state.category.value,
      supplier_id: this.state.supplier.value,
    });
    if (data.status === "success") {
      toastr.success(data.msg);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      toastr.error(data.msg);
    }
  };

  handleCategoryChange = (category) => {
    this.setState({ category });
    this.addCategoryInfos(category.value);
  };

  handleSupplierChange = (value, productIndex, index) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[productIndex].supplierPrices[index].supplier_id = value;
      })
    );
  };

  onSupplierPriceChange = (value, productIndex, index) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[productIndex].supplierPrices[index].price = value;
      })
    );
  };

  onAddProduct = () => {
    this.setState((prevState) => ({
      products: [
        ...prevState.products,
        {
          name: "",
          price: "",
          description: "",
          images: [],
          discount: "",
          tags: [],
          length: "",
          breadth: "",
          height: "",
          weight: "",
          additional: this.additional,
          supplierPrices: [],
          productAttributes: [],
        },
      ],
    }));
  };

  onCategoryInfoChange = (e, index) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[index]["additional"][e.target.name] = e.target.value;
      })
    );
  };

  onTagsChange = (tags, index) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.products[index].tags = tags.map((tag) => tag.label);
      })
    );
  };

  onInputChange = (target, index) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.products[index][target.name] = target.value;
      })
    );
  };

  imageAdd = (image, index) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.products[index].images = [...draft.products[index].images, image];
      })
    );
  };

  addSuppliers = (productIndex) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[productIndex].supplierPrices = [
          ...draft.products[productIndex].supplierPrices,
          { supplier_id: "", price: "" },
        ];
      })
    );
  };

  addProductAttributes = (productIndex) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[productIndex].productAttributes = [
          ...draft.products[productIndex].productAttributes,
          { attribute: "", value: "" },
        ];
      })
    );
  };

  onProductAttributeChange = (target, productIndex, attributeIndex) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.products[productIndex].productAttributes[attributeIndex][
          target.name
        ] = target.value;
      })
    );
  };

  render() {
    const { categoriesSelect, isSupplier, suppliers, tags } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-6 form-group">
            <label>Category</label>
            <Select
              placeholder="Select"
              id="category"
              options={categoriesSelect.map((category) => ({
                label: category.category_name,
                value: category.id,
              }))}
              onChange={this.handleCategoryChange}
              value={this.state.category}
            />
          </div>
        </div>
        {this.state.products.map((product, productIndex) => (
          <ProductItem
            {...this.props}
            onInputChange={(target) => {
              this.onInputChange(target, productIndex);
            }}
            product={product}
            handleSupplierChange={(value, supplierIndex) =>
              this.handleSupplierChange(value, productIndex, supplierIndex)
            }
            onSupplierPriceChange={(value, supplierIndex) =>
              this.onSupplierPriceChange(value, productIndex, supplierIndex)
            }
            onProductAttributeChange={(target, attributeIndex) =>
              this.onProductAttributeChange(
                target,
                productIndex,
                attributeIndex
              )
            }
            addProductAttributes={() => this.addProductAttributes(productIndex)}
            addSuppliers={() => this.addSuppliers(productIndex)}
            imageAdd={(image) => this.imageAdd(image, productIndex)}
            productIndex={productIndex}
            categoryInfos={this.state.categoryInfos}
            onTagsChange={(tags) => this.onTagsChange(tags, productIndex)}
            onCategoryInfoChange={(e) =>
              this.onCategoryInfoChange(e, productIndex)
            }
          />
        ))}
        <button class="btn btn-primary" onClick={this.onAddProduct}>
          Add Product
        </button>
        <button class="btn btn-primary ml-2" onClick={this.onSubmit}>
          Submit
        </button>
      </div>
    );
  }
}

export default hot(ProductsEntry);
