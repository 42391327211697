import React from "react";
// import { gData } from "./utils";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import "./categoriesTree.scss";
import axios from "axios";
const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}
`;

const sendDataAndReceiveResponse = async (url, data, headers = null) => {
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  let response = await axios.post(url, data, headers);
  return response;
};

const motion = {
  motionName: "node-motion",
  motionAppear: false,
  onAppearStart: (node) => {
    return { height: 0 };
  },
  onAppearActive: (node) => ({ height: node.scrollHeight }),
  onLeaveStart: (node) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

// const gData = [
//   { title: "0-0", key: "asdfs" },
//   { title: "0-1", key: "asdfsdf" },
//   {
//     title: "0-2",
//     key: "asdfsdffdg",
//     children: [{ title: "0-2-0", key: "cvbcvb" }],
//   },
// ];

class CategoriesTree extends React.Component {
  state = {
    gData: [],
    autoExpandParent: true,
    expandedKeys: ["0-0-key", "0-0-0-key", "0-0-0-0-key"],
  };

  onDragEnter = ({ expandedKeys }) => {
    this.setState({
      expandedKeys,
    });
  };
  componentDidMount = () => {
    let tree = function (data, root) {
      var r,
        o = {};
      data.forEach(function (a) {
        a.children = o[a.key] && o[a.key].children;
        o[a.key] = a;
        if (a.parentKey === root) {
          r = a;
        } else {
          o[a.parentKey] = o[a.parentKey] || {};
          o[a.parentKey].children = o[a.parentKey].children || [];
          o[a.parentKey].children.push(a);
        }
      });
      return r;
    };
    let treeCategory = [];
    this.props.categories.forEach((masterCategory) => {
      treeCategory.push(tree(JSON.parse(masterCategory["json_agg"]), 0));
    });
    this.setState({
      gData: treeCategory.sort((a, b) => a.priority - b.priority),
    });
  };

  loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
      if (item.key === key) {
        callback(item, index, arr);
        return;
      }
      if (item.children) {
        this.loop(item.children, key, callback);
      }
    });
  };

  updateCategoryGroupCode = async (info) => {
    const dropKey = +info.node.key;
    const dragKey = +info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let groupCode;
    if (!info.dropToGap) {
      groupCode = dropKey;
    } else if (
      (info.node.children || []).length > 0 && // Has children
      info.node.expanded && // Is expanded
      dropPosition === 1
    ) {
      groupCode = dropKey;
    } else {
      groupCode = info.node.parentKey ?? 0;
    }
    let children = [];
    if (groupCode === 0) {
      children = this.state.gData.map((node, index) => ({
        id: node.key,
        priority: index,
      }));
    } else {
      const dropChildren = this.getNodeChildren(info.node.parentKey);
      // const dragChildren = this.getNodeChildren(info.dragNode.parentKey);
      children = dropChildren;
    }
    try {
      await sendDataAndReceiveResponse(
        "/api/category_groups/update_group_code",
        {
          id: dragKey,
          group_code: groupCode,
          children: children,
        }
      );
      toastr.success("Moved");
    } catch (error) {
      toastr.error("Something went wrong", error.message);
    }
  };

  getNodeChildren = (key) => {
    let childrenPriority = [];
    this.loop(this.state.gData, key, (item) => {
      childrenPriority = item.children.map((node, index) => ({
        id: node.key,
        priority: index,
      }));
    });
    return childrenPriority;
  };

  onDrop = (info) => {
    const dropKey = +info.node.key;
    const dragKey = +info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dragPos = info.dragNode.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // 0 if dropped inside another 1 if dropped between gaps

    const data = [...this.state.gData];

    // Find dragObject
    let dragObj;
    this.loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (
      info.dropToGap &&
      dropPos.length === dragPos.length &&
      info.dragNode.categoryGroupId === info.node.categoryGroupId
    ) {
      // if (!info.dropToGap) {
      //   // Drop on the content
      //   this.loop(data, dropKey, (item) => {
      //     item.children = item.children || [];
      //     // where to insert
      //     item.children.push(dragObj);
      //   });
      // } else
      if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        this.loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert
          item.children.unshift(dragObj);
        });
      } else {
        // Drop on the gap
        let ar;
        let i;
        this.loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.setState(
        {
          gData: data,
        },
        () => this.updateCategoryGroupCode(info)
      );
    }
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onItemChange = (e, key, item) => {
    this.changeItemValue(item, key, e.target.value);
  };

  onFeaturedBlur = async (e, item) => {
    try {
      const { data } = await sendDataAndReceiveResponse(
        "/api/category_groups/update_featured_priority",
        {
          id: item.key,
          value: e.target.value,
        }
      );
      toastr.success(`Featured priority for ${item.title} updated`);
    } catch (error) {
      toastr.error("Something went wrong try again");
    }
  };

  changeItemValue = (item, key, value) => {
    const data1 = [...this.state.gData];

    this.loop(data1, item.key, (item, index, arr) => {
      item[key] = value;
      this.setState({ gData: data1 });
    });
  };

  allowDrop({ dropNode, dropPosition }) {
    if (!dropNode.children) {
      if (dropPosition === 0) return false;
    }
    return true;
  }

  render() {
    const { expandedKeys } = this.state;

    const customLabel = (item) => {
      return (
        <span className="cus-label">
          <span>{`${item.title} (${item.product_count})`}</span>
          <span onClick={(e) => e.stopPropagation()}>
            {item.parentKey === 0 || !item.has_subgroup ? (
              <input
                id={`featured${item.key}`}
                // onClick={(e) => e.stopPropagation()}
                type="number"
                onChange={(e) => this.onItemChange(e, "featured", item)}
                onBlur={(e) => this.onFeaturedBlur(e, item)}
                value={item.featured ?? 0}
                className="float-right mr-4 mt-1 form-control col-md-1"
              />
            ) : null}
          </span>
        </span>
      );
    };

    const loop = (data) =>
      data.map((item) => {
        if (item.is_hidden) {
          return null;
        } else if (item.children && item.children.length) {
          return (
            <TreeNode
              key={item.key}
              {...item}
              // icon={<CustomIcon image={item.image}/>}

              title={customLabel(item)}
              parentKey={item.parentKey}
            >
              {loop(item.children)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            // icon={<CustomIcon image={item.image}/>}
            key={item.key}
            {...item}
            categoryGroupId={item.categoryGroupId}
            title={customLabel(item)}
            parentKey={item.parentKey}
          />
        );
      });

    return (
      <div className="draggable-demo">
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <h4>List of Categories</h4>
        <div style={{ textAlign: "right" }}>
          <span className="mr-4 ml-4">Featured </span>
        </div>
        <Tree
          expandedKeys={expandedKeys}
          onExpand={this.onExpand}
          autoExpandParent={this.state.autoExpandParent}
          draggable
          // onDragStart={this.onDragStart}
          // onDragEnter={this.onDragEnter}
          onDrop={this.onDrop}
          // treeData={this.state.gData}
          motion={motion}
          allowDrop={this.allowDrop}
        >
          {loop(this.state.gData)}
        </Tree>
      </div>
    );
  }
}

export default CategoriesTree;
