import React from "react";
import "antd/dist/antd.css";
import { TreeSelect } from "antd";

const { SHOW_PARENT } = TreeSelect;

class SearchableCheckTreeSelect extends React.Component {
  componentDidMount() {
    const expandedKeys = [];
    const recur = (item) => {
      if (item.children !== null) {
        if (item.group_code == 0) {
          item.disabled = true;
          expandedKeys.push(item.key);
        }
        item.children.map(recur);
      }
      return item;
    };
    const treeData = JSON.parse(this.props.treeData).map(recur);
    this.setState({ treeData, expandedKeys });
  }
  state = {
    treeData: [],
    value: this.props.selectedValues,
    expandedKeys: [],
  };

  onChange = (value) => {
    this.setState({ value });
  };

  filterTreeNode = (search, item) => {
    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  render() {
    const tProps = {
      treeData: this.state.treeData,
      value: this.state.value,
      treeExpandedKeys: this.state.expandedKeys,
      onChange: this.onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      onTreeExpand: (expandedKeys) => this.setState({ expandedKeys }),
      filterTreeNode: this.filterTreeNode,
      placeholder: "Please select",
      style: {
        width: "100%",
      },
    };
    return (
      <>
        <TreeSelect {...tProps} />
        <input
          id="category"
          type="hidden"
          name="categories"
          value={this.state.value}
        />
      </>
    );
  }
}

export default SearchableCheckTreeSelect;
